module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#7b8735","name":"Peak Express Shipping and Services","short_name":"Peak Express Shipping and Services","description":"Peak Express Shipping and Services provides air and ocean freight forwarding, custom brokerage, freight brokerage, inventory management and packaging, order fulfillment, supply chain consulting, transportation management and warehousing and distribution.","display":"standalone","icon":"src/images/gatsby-icon.png","lang":"en","localize":[{"start_url":"/id/","lang":"id","name":"Honda","short_name":"Honda Semarang","description":"The Power of Dreams"},{"start_url":"/jw/","lang":"jw","name":"Honda","short_name":"Honda Semarang","description":"The Power of Dreams"}],"scope":"/","start_url":"/","theme_color":"#7b8735","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
