import { extendTheme } from "@chakra-ui/react"
import Link from "../theme/Link.js"

const customTheme = extendTheme({
  colors: {
    primary: {
      100: "#ffffff",
      200: "#000000",
      300: "#e5021f", // primary
      400: "#4e888a", // subtle blue
      500: "#f8f1d4", // pale yellow
      600: "#f19645", // orange
      700: "#17292e", // Darker
      800: "",
      900: "",
    },
  },
  components: {
    Link,
  },
})

export default customTheme
