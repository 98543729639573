/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ContactBar from "./contact-bar"

import NavBar from "./navbar"
import FooterBlock from "../blocks/footer-block"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ContactBar />
      <NavBar siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: `var(--size-content)`,
          padding: `var(--size-gutter)`,
        }}
      >
        <main>{children}</main>
      </div>
      <FooterBlock />
    </>
  )
}

export default Layout

/* <a href="https://www.freepik.com/free-photo/port-de-barcelona-night_1491287.htm#query=shipping&position=22&from_view=search&track=sph">Image by bearfotos</a> on Freepik */
/* <a href="https://www.freepik.com/free-photo/airplane-sunset_4291511.htm#query=cargo&position=49&from_view=search&track=sph">Image by jcomp</a> on Freepik */
/* <a href="https://www.freepik.com/free-photo/transportation-truck-delivering-cargo-container-multiple-lane-highway-generative-ai_40937309.htm#query=cargo&position=38&from_view=search&track=sph">Image by vecstock</a> on Freepik */
/* <a href="https://www.freepik.com/free-photo/warehouse-worker-standing-large-storage-center-showing-ok-hand-gesture-satisfied-delivering-goods_11451282.htm?query=cargo#from_view=detail_alsolike">Image by aleksandarlittlewolf</a> on Freepik */
/* <a href="https://www.freepik.com/free-vector/worldmap-background-design_1019996.htm#query=world%20map&position=11&from_view=search&track=ais">Image by evening_tao</a> on Freepik */
