import React from "react"
import { Flex } from "@chakra-ui/react"

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      px="4"
      py="2"
      bg="primary.100"
      color="primary.700"
      {...props}
    >
      {children}
    </Flex>
  )
}

export default NavBarContainer
