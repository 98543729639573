import React from "react"
import MenuLinks from "./menu-links"
import MenuToggle from "./menu-toggle"
import Logo from "./logo"
import NavBarContainer from "./navbar-container"
// import LanguageSwitcher from "./LanguageSwitcher"

const NavBar = ({ siteTitle, ...props }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <NavBarContainer {...props}>
      <Logo siteTitle={siteTitle} size="lg" />
      {/* <LanguageSwitcher /> */}
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks isOpen={isOpen} />
    </NavBarContainer>
  )
}

export default NavBar
