import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby-plugin-react-i18next"
import styled from "styled-components"

const CommonLink = styled(Link)`
  font-size: var(--font-sm);
  text-decoration: none;
`
const HeaderLogo = styled(CommonLink)`
  max-width: 20rem;
  margin-top: -2.3rem;
`
const FooterLogo = styled(CommonLink)`
  max-width: 10rem;
  margin-top: -1.2rem;
`

const Logo = ({ siteTitle, size }) =>
  size === "lg" ? (
    <HeaderLogo to="/">
      <StaticImage src="../images/pess-logo-header.png" alt={siteTitle} />
    </HeaderLogo>
  ) : (
    <FooterLogo to="/">
      <StaticImage src="../images/pess-logo-header.png" alt={siteTitle} />
    </FooterLogo>
  )

export default Logo
