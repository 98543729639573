import React from "react"
import { Box, Icon } from "@chakra-ui/react"
import { MdMenu } from "@react-icons/all-files/md/MdMenu"
import { MdClose } from "@react-icons/all-files/md/MdClose"

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      <Icon boxSize={6} as={isOpen ? MdClose : MdMenu} />
    </Box>
  )
}

export default MenuToggle
