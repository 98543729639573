import React from "react"
import { Box, Stack } from "@chakra-ui/react"
import { MdDirectionsCar } from "@react-icons/all-files/md/MdDirectionsCar"

import MenuItem from "./menu-item"
import { withTranslation } from "react-i18next"

const MenuLinks = ({ isOpen, t }) => (
  <Box
    display={{ base: isOpen ? "block" : "none", md: "block" }}
    flexBasis={{ base: "100%", md: "auto" }}
  >
    <Stack
      spacing={1}
      align={["stretch"]}
      justify={["center", "space-between", "flex-end", "flex-end"]}
      direction={["column", "row", "row", "row"]}
      pt={[4, 4, 0, 0]}
    >
      <MenuItem to="/" icon={MdDirectionsCar}>
        Home
      </MenuItem>
      <MenuItem to="/about">{t`about-us`}</MenuItem>
      <MenuItem to="/contact">{t`contact-us`}</MenuItem>
      <MenuItem to="/services">{t`services`}</MenuItem>
    </Stack>
  </Box>
)

export default withTranslation("translations")(MenuLinks)
