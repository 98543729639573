import { defineStyleConfig } from "@chakra-ui/react"

const Link = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {},
  // Two sizes: sm and md
  sizes: {
    sm: {},
    md: {},
  },
  // Two variants: outline and solid
  variants: {
    menuItem: {
      color: "primary.700",
      padding: "2",
      marginTop: "0",
      _focus: {
        backgroundColor: "primary.500",
        textDecor: "none",
      },
      _hover: {
        backgroundColor: "primary.500",
        textDecor: "none",
      },
    },
    solid: {
      bg: "purple.500",
      color: "white",
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "menuItem",
  },
})

export default Link
